import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import addToMailchimp from 'gatsby-plugin-mailchimp'

const Holder = styled.div`
  label {
    display: none;
  }
  display: grid;
  grid-template-columns: 2fr 1fr;
  input[type="email"] {
    margin: 0;
    width: 100%;
  }
  button {
    color: ${props => props.theme.colours.white};
    background-color: ${props => props.theme.colours.midGrey};
  }
`;

const Message = styled.div`
`;

const validationSchema = Yup.object().shape( {
  email: Yup.string()
    .email( 'Enter a Valid Email' )
    .required( 'Email is Required' ),
} );

function SubscribeForm() {

  const [ data, setData ] = useState( null );

  if ( data ) {
    return (
      <Message>
        <p dangerouslySetInnerHTML={{ __html: data.msg }}/>
      </Message>
    )
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={( values, { setSubmitting } ) => {
        setTimeout( () => {
          setSubmitting( false );

          addToMailchimp( values.email )
            .then( data => {
              setData( data );
            } )

        }, 400 );
      }}
    >
      {( { isSubmitting } ) => (
        <Form>
          <Holder>
            <div className='field-holder'>
              <label htmlFor="email" className="label">Email</label>
              <Field className="small" aria-label="email" type="email" name="email" placeholder="Email address"/>
            </div>
            <button type="submit" className="no-arrow" disabled={isSubmitting}>Subscribe</button>
            <ErrorMessage name="email" component="p"/>
          </Holder>
        </Form>
      )}
    </Formik>
  )
}

export default SubscribeForm;
