import React from "react"
import styled from "styled-components";
import SrbLogo from '../../assets/svg/srb.inline.svg';
import SubscribeForm from "../atoms/SubscribeForm";

const Holder = styled.footer`
  color: ${props => props.theme.colours.midGrey};
  background-color: ${props => props.theme.colours.lightGrey};
  padding: 3rem 2rem;
  margin-top: 4rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin-top: 8rem;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  h4 {
    color: ${props => props.theme.colours.black};
    margin: 0 0 2rem 0;
  }

  p {
    ${(props) => props.theme.fluidType(-1)};
  }

  img {
    height: 1.3rem;
    width: auto;
    display: inline-block;
    margin: 1rem 1rem 0 0;
    @media ( ${props => props.theme.breakpoints.xl} ) {
      height: 2rem;
      margin: 1rem 1.5rem 0 0;
    }
  }

  svg {
    max-width: 20rem;
  }
  > div {
    > :first-child { margin-top: 0; }
    > :last-child { margin-bottom: 0; }
  }
`;

function Footer() {
  return (
    <Holder>
      <Grid>
        <div>
          <SrbLogo />
          <p>The Circular is a project of <a href="https://sydneyreviewofbooks.com/" target="_blank"
                                             rel="noopener noreferrer">The Sydney Review of Books</a>. We’re supported
            by the <a  target="_blank" rel="noopener noreferrer" href="https://australiacouncil.gov.au/investment-and-development/sector-recovery-initiatives/">Australia Council’s Sector Recovery Initiative fund</a>.</p>
        </div>
        <div>
          <SubscribeForm />
          <p>We send a weekly newsletter featuring the most interesting, useful and surprising recent non-fiction published by and about Australian writers.</p>
        </div>
        <div>
          <p>We acknowledge the traditional custodians of the lands on which we work, the Burramattagal people of the Darug nation, and pay our respects to elders past, present, and emerging. Sovereignty was never ceded, and the struggles for justice are ongoing. We acknowledge the traditional custodians of the lands this digital platform reaches.</p>
        </div>
      </Grid>
    </Holder>
  )
}

export default Footer
