import React, { Component } from "react"
import { Link } from "gatsby"
import Container from "../atoms/Container"
import styled from 'styled-components'


const Holder = styled.div`
  ${(props) => props.theme.fluidType(-1)};
  color: ${props => props.theme.colours.grey};
  position: relative;
  z-index: 2;
  @media( ${props => props.theme.breakpoints.md} ) {
    ${(props) => props.theme.fluidType(1)};
  }
  ul {
    display: flex;
    justify-content: center;
    list-style: none;
  }
  li a {
    text-decoration: none;
  }
  li:not(:last-child):after {
    padding: 0 1rem;
    content: "·";
  }
`;

class Navigation extends Component {
  render() {
    return (
      <Container>
        <nav>
          <Holder>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about/">About</Link>
              </li>
              <li>
                <Link to="/subscribe/">Subscribe</Link>
              </li>
            </ul>
          </Holder>
        </nav>
      </Container>
    )
  }
}

export default Navigation
