import React, {Component} from "react";
import PropTypes from "prop-types";

import Footer from "../components/molecules/Footer";

import GlobalStyles from "../components/atoms/GlobalStyles";
import {ThemeProvider} from "styled-components";
import {theme} from "../utils/styling";
import Navigation from "../components/molecules/Navigation";

class Index extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <Navigation />
          <main>{this.props.children}</main>
          <Footer />
        </>
      </ThemeProvider>
    );
  }
}

Index.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Index;
