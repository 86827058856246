import {createGlobalStyle} from "styled-components"

const GlobalStyle = createGlobalStyle`

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${(props) => props.theme.fluidType(0)};

    * { box-sizing: border-box; }
  }

  body {
    margin: 0;
    // Use system fonts: https://css-tricks.com/snippets/css/system-font-stack/
    font-family: IBMPlexSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: ${(props) => props.theme.colours.black};
    background-color: ${(props) => props.theme.colours.white};
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: IBMPlexMono, monospace;
    line-height: 1.1;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .mono {
    font-family: IBMPlexMono, monospace;
  }

  p,
  ol, ul, li,
  code, kbd, pre, samp {
    line-height: 1.5;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: bold;
  }

  h1, .h1 { ${(props) => props.theme.fluidType(4)}; }

  h2, .h2 { ${(props) => props.theme.fluidType(3)}; }

  h3, .h3 { ${(props) => props.theme.fluidType(2)}; }

  h4, .h4 { ${(props) => props.theme.fluidType(1)}; }

  h5, .h5 { ${(props) => props.theme.fluidType(0)}; }

  h6, .h6 { ${(props) => props.theme.fluidType(-1)}; }
  
  .large {
    @media( ${props => props.theme.breakpoints.md} ) {
      ${(props) => props.theme.fluidType(2)};
    }
  }

  li {
    margin-top: 0;
    margin-bottom: 0;
  }

  small, p.small, .small { ${(props) => props.theme.fluidType(-1)}; }

  code, kbd, pre, samp {
    font-family: IBMPlexMono, monospace;
    white-space: normal;
  }

  ul {
    padding-left: 1rem;
    list-style-type: disc;
  }

  ol {
    padding-left: 1rem;
    list-style-type: decimal;
  }

  video {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  em, i { font-style: italic; }

  strong, b { font-weight: bold; }

  blockquote {
    font-weight: bold;
    padding-left: 1rem;
  }

  a { color: inherit; }

  sup, sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }

  sub { top: 0.4em; }

  label {
    ${(props) => props.theme.fluidType(-1)};
    line-height: 1.2;
    font-weight: normal;
  }

  .text-input,
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="range"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  textarea {
    display: block;
    font-family: inherit;
    ${(props) => props.theme.fluidType(0)};
    padding: 0.4rem 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 0;
    line-height: 2rem;
    background-color: ${props => props.theme.colours.white};
    -webkit-appearance: none;

    &:focus {
      border: 1px ${(props) => props.theme.colours.focus} solid;
    }
  }

  .file-input,
  input[type="file"],
  .radio-input,
  input[type="radio"],
  .checkbox-input,
  input[type="checkbox"],
  select {
    font-family: inherit;
  }

  .button,
  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"] {
    display: inline-block;

    padding: 0 0.75rem;
    font-family: IBMPlexMono, monospace;
    ${(props) => props.theme.fluidType(0)};
    line-height: 2.8rem;
    text-decoration: none;
    white-space: nowrap;
    border: none;
    border-radius: 2px;
    cursor: pointer;

    color: inherit;
    background-color: ${props => props.theme.colours.yellow};

    &:after {
      content: '→';
      margin-left: 0.5rem;
    }
    &.no-arrow:after {
      display: none;
    }

    &:hover {
      text-decoration: none;
    }

    &.link {
      border: none;
      padding: 0;
      background-color: transparent;
    }
  }

  .errorMsg {
    color: ${(props) => props.theme.colours.error};
  }
`

export default GlobalStyle
